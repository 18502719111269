<template>
   <ObiText class="obi-sidebar">
      <ObiText as="ul" class="nav flex-column nav-main">
         <ObiText as="li" class="nav-item" v-for="routeItem in routeItems" :key="routeItem.id">
            <RouterLink
               class="nav-link"
               :class="{ active: routeIsActive(routeItem) }"
               :to="routeItem.to"
               v-if="authUser.permissions.includes(routeItem.permission)"
            >
               <component :is="routeItem.icon" />
               <span v-html="$t(routeItem.text)" />
            </RouterLink>
            <ObiText v-if="routeItem.childs" as="ul" class="nav flex-column nav-main" style="padding-left: 40px;">
               <ObiText as="li" class="nav-item" v-for="childItem in routeItem.childs" :key="childItem.id">
                  <RouterLink class="nav-link" :class="{ active: routeIsActive(childItem) }" :to="childItem.to">
                     <component :is="childItem.icon" />
                     <span v-html="$t(childItem.text)" />
                  </RouterLink>
               </ObiText>
            </ObiText>
         </ObiText>
      </ObiText>
      <ObiText as="ul" class="nav flex-column nav-footer">
         <ObiText as="li" class="nav-item">
            <RouterLink v-if="isAdmin" class="nav-link" :to="{ name: 'settings-edit' }">
               <i class="mdi mdi-cog" />
               <ObiText v-html="$t('sidebar.settings')" />
            </RouterLink>
         </ObiText>
         <ObiText as="li" class="nav-item">
            <ObiText class="nav-link" @click="logout">
               <i class="mdi mdi-logout" />
               <ObiText v-html="$t('sidebar.logout')" />
            </ObiText>
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import { mapGetters } from 'vuex';
import IconDevice from '@/components/Icons/general/IconDevice';
import IconPortal from '@/components/Icons/general/IconPortal';
import IconVisitor from '@/components/Icons/general/IconVisitor';
// import IconLocation from '@/components/Icons/general/IconLocation';
import IconDashboard from '@/components/Icons/general/IconDashboard';
import IconIntegration from '@/components/Icons/general/IconIntegration';
//import IconQrMenu from '@/components/Icons/general/IconQrMenu';
import IconReport from '@/components/Icons/general/IconReport';

export default {
   name: 'ObiSidebar',
   props: {},
   data() {
      return {
         routeItems: [
            {
               id: 0,
               text: 'sidebar.dashboard',
               icon: IconDashboard,
               to: {
                  name: 'dashboard-index',
               },
               permission: 'view-dashboard',
            },
            {
               id: 4,
               text: 'sidebar.portals',
               icon: IconPortal,
               to: {
                  name: 'portals-index',
               },
               permission: 'view-portals',
            },
            {
               id: 2,
               text: 'sidebar.devices',
               icon: IconDevice,
               to: {
                  name: 'devices-index',
               },
               permission: 'view-devices',
            },
            {
               id: 3,
               text: 'sidebar.visitors',
               icon: IconVisitor,
               to: {
                  name: 'visitors-index',
               },
               permission: 'view-clients',
            },
            {
               id: 5,
               text: 'sidebar.integrations',
               icon: IconIntegration,
               to: {
                  name: 'integrations-index',
               },
               permission: 'view-integrations',
            },
            {
               id: 6,
               text: 'sidebar.reports',
               icon: IconReport,
               to: {
                  name: 'multiple-sessions-by-mac',
               },
               permission: 'view-management-stats',
            },
         ],
         bottomRouteItems: [
            {
               id: 100,
               text: 'sidebar.settings',
               icon: 'mdi mdi-cog',
               only_admin: true,
               to: {
                  name: 'settings-edit',
               },
            },
         ],
      };
   },
   methods: {
      routeIsActive(routeItem) {
         return routeItem.to && this.$route.name === routeItem.to.name;
      },
      logout() {
         this.$store.dispatch('auth/logout', { redirectRoute: 'auth-login' });
      },
   },
   computed: {
      ...mapGetters({ isAdmin: 'auth/isAdmin' }),
      ...mapGetters('auth', ['authUser', '']),
   },
};
</script>

<style lang="scss" scoped>
.obi-sidebar {
   height: 100%;
   display: grid;
   padding: 14px 20px;
   gap: 15px;
   grid-template-rows: 1fr auto;

   .nav {
      &.nav-main {
         gap: 15px;
      }
      &.nav-footer {
         justify-content: flex-start;
         position: sticky;
         bottom: 20px;
      }

      .nav-item {
         .nav-link {
            gap: 20px;
            display: flex;
            color: #777d96;
            border-radius: 4px;
            align-items: center;
            justify-content: flex-start;

            svg {
               width: 22px;
               height: 22px;
               stroke: #777d96;
            }

            &.active {
               background-color: #e5efff;
               color: #33508c;
               svg {
                  stroke: #2f71f2;
               }
            }
         }
      }
   }
}
</style>
